<template>
  <div
    class="stars"
    :class="{ dark, big }"
  >
    <svg
      v-for="n in total"
      :key="n"
      :class="{ on: n <= roundedScore }"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.432 15.879"
    >
      <path
        d="M8.611.265a.5.5,0,0,0-.056,0A.548.548,0,0,0,8.1.561L5.769,5.094.743,5.727a.549.549,0,0,0-.474.613.543.543,0,0,0,.151.313l3.56,3.639L3.024,15.3a.549.549,0,0,0,.433.644.559.559,0,0,0,.352-.05l4.527-2.283,4.44,2.461a.545.545,0,0,0,.8-.557l-.756-5.055,3.7-3.488a.549.549,0,0,0,.029-.774.535.535,0,0,0-.317-.168L11.24,5.2,9.087.582A.55.55,0,0,0,8.611.265Z"
        transform="translate(-0.265 -0.265)"
        fill="#363636"
        fill-rule="evenodd"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    score?: number
    total?: number
    dark?: boolean
    big?: boolean
  }>(),
  {
    score: 0,
    total: 5,
    dark: false,
    big: false,
  },
)

const roundedScore = computed(() => Math.round(props.score))
</script>

<style lang="scss">
.stars {
  &.dark {
    svg {
      path {
        fill: $dark;
      }
    }
  }

  &.big {
    svg {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.85rem;
    }
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.5rem;

    &.on {
      path {
        fill: $orange;
      }
    }

    path {
      fill: $white;
    }
  }
}
</style>
